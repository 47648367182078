import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ContentHtml } from '../ContentHtml';
import { ContentPackageWrapper } from '../../responsive/organisms/ContentPackageWrapper';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { ParagraphWrapper } from '../ParagraphWrapper';

const ContentHtmlPackageInner = ({ data }) => {
  if (!data.htmlContent) {
    return <div />;
  }
  if (data.contentPackageHeader) {
    return (
      <ContentPackageWrapper
        contentPackageHeader={data.contentPackageHeader}
        backgroundColor={data.backgroundColor ? data.backgroundColor : 'white'}
        extraStyles={css`
          ${facePaint({
            'contain-intrinsic-size': [
              '0 599px',
              '0 590px',
              '0 737px',
              '0 922px'
            ]
          })}
        `}
      >
        <ContentHtml data={data.htmlContent.entity} />
      </ContentPackageWrapper>
    );
  }
  return <ContentHtml data={data.htmlContent.entity} />;
};

ContentHtmlPackageInner.propTypes = {
  data: PropTypes.shape({
    htmlContent: PropTypes.objectOf(PropTypes.any),
    entity: PropTypes.objectOf(PropTypes.any),
    body: PropTypes.string,
    script: PropTypes.string,
    contentPackageHeader: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired
};

export const ContentHtmlPackage = ParagraphWrapper(ContentHtmlPackageInner);
